import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Prijslijst from "gatsby-theme-nieuwbouw/src/components/prijslijst";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <StaticImage src={'../img/beschikbaar/GettyImages-517021139@2x.png'} alt={'water'} />
            <div
                className={'bg-white py-5'}
            >
                <Container
                    fluid={'xl'}
                    as={'section'}
                >
                    {data.allBouwtype.nodes.map(bouwtype =>
                        <section key={bouwtype.id} className={'my-5'}>
                            <header>
                                <h3>
                                    <span>Type</span> {bouwtype.algemeen.omschrijving}
                                </h3>
                            </header>
                            <Row key={bouwtype.id} className={"my-5"}>
                                <Col md={6}>
                                    <div className={"position-relative"}>
                                        {bouwtype.hoofdfoto &&
                                        <GatsbyImage
                                            alt={"artist impression"}
                                            image={bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
                                        }
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Prijslijst bouwnummers={bouwtype.bouwnummers || []}/>
                                </Col>
                            </Row>
                        </section>
                    )}

                    <div dangerouslySetInnerHTML={{__html: data.file.childMarkdownRemark.html}} />
                </Container>
            </div>
        </Page>
    );
}

interface PageQueryData {
    allBouwtype: {
        nodes: Bouwtype[]
    }
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "beschikbaar"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        allBouwtype(sort: {fields: algemeen___omschrijving}) {
            nodes {
                id
                ...Bouwtype
                bouwnummers {
                    id
                    ...BouwnummerModal
                }
                diversen {
                    objectcode
                }
            }
        }
    }
`
